/* eslint-disable react/prop-types */
import { Grid } from '@material-ui/core';
import styled from '@emotion/styled';
import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import theme from '../theme/theme';

const TechCard = styled.div`
    display: flex;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 3px 5px 11px 5px rgba(0, 0, 0, 0.07);
    transition: 0.4s ease;
    height: 100%;
    align-items: center;
    h5 {
        margin-left: 10px;
        font-size: 17px;
        font-weight: 600;
    }
    svg {
        width: 50px;
        height: auto;
    }
    svg * {
        transition: 0.1s ease;
    }
    &:hover {
        box-shadow: 3px 5px 17px 5px rgba(0, 0, 0, 0.12);
        background-color: ${theme.primary};
        color: white;
        svg * {
            fill: white;
        }
        h5 {
          color: white;
        }
    }
`;

const FWGrid = css`
    margin-top: 10px;
`;

const TechGrid = css`
    margin-top: 20px;
    margin-bottom: 35px;
    @media screen and (min-width: 1000px) {
      width: 490px;
    }
`;

const LargeHeading = styled.h5`
    font-size: 20px !important;
`;

const TechBox = ({
  technologies, fullWidth,
}) => (
  <Grid css={fullWidth ? FWGrid : TechGrid} container spacing={fullWidth ? 3 : 2}>
    {technologies.map((el) => (
      <Grid key={el.name} item xs={fullWidth ? 12 : 6} sm={6} md={fullWidth ? 3 : 6}>
        {el.link
        && (
          <Link to={el.link}>
            <TechCard>
              <div>
                {el.icon}
              </div>
              {fullWidth ? <LargeHeading>{el.name}</LargeHeading> : <h5>{el.name}</h5>}
            </TechCard>
          </Link>
        )}
        {!el.link
        && (
        <TechCard>
          <div>
            {el.icon}
          </div>
          {fullWidth ? <LargeHeading>{el.name}</LargeHeading> : <h5>{el.name}</h5>}
        </TechCard>
        )}
      </Grid>
    ))}
  </Grid>
);

export default TechBox;
